<template>
  <div class="flex-container">
    <div class="cryptofoundry-io-logo">
      <a href="https://www.cryptofoundry.io/">
        <img v-bind:src="logoUrl" alt="CryptoFoundry.io" />
      </a>
    </div>
    <h1>Track your favorite cryptocurrencies and watch them in realtime.</h1>
  </div>
</template>

<script>

export default {
  data: function () {
    return  {
      logoUrl: process.env.BASE_URL + '/logo.png'
    }
  },
  mounted: function () {
    this.$gtag.event('pageView', { src: 'organic' })
  }
}
</script>

<style>
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 84px);
  text-align: center;
  overflow: hidden;
}
.cryptofoundry-io-logo {
  display: block;
  width: 250px;
  height: 82px;
  margin: 0 auto;
}
.cryptofoundry-io-logo img {
  width: 250px;
  height: 82px;
}

.flex-container h1,
.flex-container h2 {
  margin: 0 auto;
  font-weight: 200;
  font-variant: small-caps;
  max-width: 300px;
}

.flex-container h1 {
  margin-top: 10px;
  font-size: 16px;
  color: #999;
}

</style>
