import Vue from 'vue'
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "UA-202743895-1" }
});

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import './assets/css/app.css'

import VueDarkMode from "@growthbunker/vuedarkmode"
Vue.use(VueDarkMode)

import App from './App.vue'
import routes from './routes'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
