import AppLayout from '../components/AppLayout.vue'

const routes = [
  {
    path: '/',
    component: AppLayout
  },
  {
    path: '*',
    component: AppLayout
  }
]

export default routes
